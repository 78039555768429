import React from "react";
import { css } from "@emotion/react";
import { Container, Panel, Map, Logo, Flex } from ".";
import { breakpoints as bp, colors } from "../theme";
import { BsFillTelephoneFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

const TextWithIcon = ({ icon: Icon, text, children }) => (
  <span
    css={css`
      display: flex;
      svg {
        margin-right: 20px;
      }
    `}
  >
    <Icon size={20} />
    <span
      css={css`
        a {
          color: ${colors.gold};
          font-weight: 400;
        }
      `}
    >
      {text || children}
    </span>
  </span>
);

function Footer() {
  return (
    <Panel color={colors.blue[400]}>
      <Container size="large" css={{ color: "white", width: "100%" }}>
        <Flex css={{ justifyContent: "space-between" }}>
          <Flex.Column>
            <h3 css={{ fontWeight: 600, margin: 0, color: colors.gold }}>
              Get in touch
            </h3>
            <hr
              css={{
                border: 0,
                height: 1,
                backgroundColor: "white",
                margin: "10px 0 20px 0",
                opacity: 0.2,
              }}
            />
            <div css={{ lineHeight: "2.2rem" }}>
              <TextWithIcon
                text="407-862-6464 (phone)"
                icon={BsFillTelephoneFill}
              />
              <TextWithIcon
                text="407-862-6321 (fax)"
                icon={BsFillTelephoneFill}
              />
            </div>
            <br />
            <TextWithIcon icon={MdEmail}>
              <a href="#">marc@orlandobrokersinsurance.com</a>
            </TextWithIcon>
            <br />
          </Flex.Column>

          <Flex.Column
            css={css`
              padding-left: 55px;
              @media (max-width: ${bp.small}px) {
                padding-left: 0;
                margin-top: 40px;
              }
            `}
          >
            <h3
              css={{
                fontWeight: 600,
                margin: 0,
                color: colors.gold,
              }}
            >
              Visit our Office
            </h3>
            <hr
              css={{
                border: 0,
                height: 1,
                backgroundColor: "white",
                margin: "10px 0 20px 0",
                opacity: 0.2,
              }}
            />
            <p
              css={{
                fontSize: ".9rem",
                opacity: 0.9,
                lineHeight: "1.5rem",
                marginBottom: 35,
              }}
            >
              241 S. Westmonte Drive
              <br />
              Suite 1040
              <br />
              Altamonte Springs, FL 32714
            </p>

            <Map />
            <small css={{ display: "block", marginTop: 20 }}>
              <a
                css={css`
                  color: ${colors.gold};
                  font-weight: 400;
                `}
                target="_blank"
                href="https://www.google.com/maps/place/241+S+Westmonte+Dr+Suite+1040,+Altamonte+Springs,+FL+32714/@28.659189,-81.3934675,16.99z/data=!4m5!3m4!1s0x88e77173a4d52119:0x71db46566d93ae1a!8m2!3d28.6591748!4d-81.3913093"
              >
                View on Google Maps
              </a>
            </small>
          </Flex.Column>

          <Flex.Column
            css={css`
              padding-left: 55px;
              @media (max-width: ${bp.small}px) {
                padding-left: 0;
                margin-top: 50px;
              }
            `}
          >
            <h3 css={{ fontWeight: 600, margin: 0, color: colors.gold }}>
              Our vision &amp; mission
            </h3>
            <hr
              css={{
                border: 0,
                height: 1,
                backgroundColor: "white",
                margin: "10px 0 20px 0",
                opacity: 0.2,
              }}
            />
            <p css={{ fontSize: ".8rem", opacity: 0.9, lineHeight: "1.6rem" }}>
              Brokers Insurance is ready to assist you by providing a variety of
              quality insurance products underwritten by the largest national
              companies.
            </p>
            <p css={{ fontSize: ".8rem", opacity: 0.9, lineHeight: "1.6rem" }}>
              Brokers Insurance offers the value of comparison with the
              confidence of local customer service. Call today for a
              confidential evaluation of your insurance needs.
            </p>
          </Flex.Column>
        </Flex>

        <hr
          css={css`
            background-color: white;
            opacity: 0.2;
            border: 0;
            height: 1px;
            margin: 45px auto 20px;
            width: 100%;
          `}
        />

        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}
        >
          <Logo color="white" size="30" />

          <p css={{ margin: 0, fontSize: ".77rem", opacity: 0.6 }}>
            Copyright ©2022 Brokers Insurance. All rights reserved.
          </p>
        </div>
      </Container>
    </Panel>
  );
}

export default Footer;
